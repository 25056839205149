<template>
  <v-container
    fluid
    fill-height
    style="background-color:white;"
  >
    <v-row>
      <v-col cols="9">
        <v-card
          flat
          class="mt-n5"
        >
          <v-card-title class="d-flex align-center">
            {{ currentCaseForm.name }}
            <v-btn
              icon
            >
              <v-icon
                small
                color="accent"
                @click="changeCaseModelName"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text
            class="d-flex mt-n4"
            style="min-height: 20px"
          >
            <v-col
              cols="6"
            >
              <div>
                <div>
                  {{ $t('common|description') }}: {{ currentCaseForm.description }}
                  <v-btn
                    icon
                  >
                    <v-icon
                      small
                      color="accent"
                      @click="changeCaseModelDescription"
                    >
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </div>
                <div>
                  {{ $t('common|category') }}: {{ currentCaseForm.category }}
                  <v-icon
                    small
                    color="accent"
                    class="ml-2"
                    @click="changeCategory(currentCaseForm.category)"
                  >
                    mdi-pencil
                  </v-icon>
                </div>
                <div>
                  {{ $t('common|date_created') }}: {{ currentCaseForm.createdAt | date }}
                </div>
                <div>
                  {{ $t('common|date_updated') }}: {{ currentCaseForm.updatedAt | dateTime }}
                </div>
                <div
                  v-if="currentCaseForm.createdBy && currentCaseForm.createdBy.accountName"
                >
                  {{ $t('common|created_by') }}: {{ currentCaseForm.createdBy.accountName }}
                </div>
                <div
                  v-if="currentCaseForm.createdBy && currentCaseForm.createdBy.companyData"
                >
                  {{ $t('common|created_by') }}: {{ currentCaseForm.createdBy.companyData.companyName }}
                </div>
              </div>
            </v-col>
            <v-col
              cols="4"
            >
              <div class="d-flex flex-column">
                <v-btn
                  color="primary"
                  @click="openEditDialog"
                >
                  {{ $t('common|edit_tabs') }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="my-4"
                  @click="openGeneralQuestionnaire"
                >
                  {{ $t('questionnaire|questionnaire') }}
                </v-btn>
              </div>
            </v-col>
          </v-card-text>
        </v-card>
        <v-card
          height="100%"
          flat
          class="mt-n3 mb-2"
        >
          <div class="d-flex align-center px-4 mt-5">
            <h3 class="mt-0 mb-0">
              {{ $t('case|workbenches') }}
            </h3>
            <div
              v-if="account.accountType === 'lawyer'"
              class="d-flex flex-grow-1 align-center justify-end"
            >
              <v-btn
                class="ma-1"
                color="primary"
                small
                dark
                @click.prevent="editWorkbenchesDialog = true"
              >
                {{ $t('actions|edit_workbenches') }}
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                small
                dark
                @click.prevent="addWorkbench"
              >
                {{ $t('actions|add_workbench') }}
              </v-btn>
            </div>
          </div>
          <div class="d-flex align-center pa-4">
            <v-tabs
              v-model="selectedWbTab"
              active-class="white"
              slider-color="primary"
              next-icon="mdi-arrow-right"
              prev-icon="mdi-arrow-left"
              show-arrows
            >
              <v-tab
                v-for="workbench in currentCaseForm.workbenches"
                :key="workbench._id"
                :name="workbench.name"
                :to="`/cases-models/${$route.params._id}/${workbench._id}`"
              >
                {{ workbench.name }}
              </v-tab>
              <v-tab
                v-if="account.accountType === 'lawyer'"
                @click.prevent="addWorkbench"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-tab>
            </v-tabs>
          </div>
          <router-view />
        </v-card>
      </v-col>
      <v-col cols="3">
        <DocumentSuggestions />
      </v-col>
    </v-row>
    <ChangeCaseModelCategory :current-case-form="currentCaseForm" />
    <EditCaseModelWorkbenches
      :current-case-form="currentCaseForm"
    />
    <!-- <v-dialog
      v-model="generalQDialog"
      keydown
      max-width="1200px"
      transition="dialog-bottom-transition"
      style="position: relative;"
    >
      <GeneralQuestionnaireForm
        :key="generalQDialog"
        :template-data="allCurrentTemplateData"
        :workbench="currentWorkbench"
        :current-case-form="currentCaseForm"
        :form-id="currentCaseForm._id"
        @close="generalQDialog = false"
      />
    </v-dialog> -->
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import DocumentSuggestions from './DocumentSuggestions.vue'
import ChangeCaseModelCategory from '../../components/dialogs/ChangeCaseModelCategory.vue'
import EditCaseModelWorkbenches from './EditCaseModelWorkbenches.vue'
// import GeneralQuestionnaireForm from '../../components/forms/GeneralQuestionnaireForm.vue'

export default {
  components: {
    DocumentSuggestions,
    ChangeCaseModelCategory,
    EditCaseModelWorkbenches
    // GeneralQuestionnaireForm
  },
  data () {
    return {
      selectedCategory: '',
      tab: null,
      selectedWorkbench: '',
      generalQDialog: false,
      selectedWbTab: 1
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      caseForms: state => state.caseForms.caseForms
    }),
    currentCaseForm () {
      if (!this.caseForms) return null
      return this.caseForms.find(c => c._id === this.$route.params._id)
    },
    currentWorkbench () {
      return this.currentCaseForm.workbenches.find(w => w._id === this.selectedWorkbench)
    },
    allCurrentTemplateData () {
      return this.currentCaseForm.workbenches.reduce((acc, w) => {
        acc.push(
          ...w.templates
        )
        return acc
      }, [])
    }
  },
  async created () {
    EventBus.$on('suggestion-is-selected', this.selectSuggestion)
    EventBus.$on('suggestion-to-delete', this.deleteReqiredDocumentSuggestion)
  },
  beforeDestroy () {
    EventBus.$off('suggestion-to-delete', this.deleteReqiredDocumentSuggestion)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      updateCaseForm: 'caseForms/updateCaseForm',
      addCaseFormWorkbench: 'caseForms/addCaseFormWorkbench',
      addCaseFormWorkbenchTemplate: 'caseForms/addCaseFormWorkbenchTemplate',
      addCaseFormWorkbenchRequiredDocument: 'caseForms/addCaseFormWorkbenchRequiredDocument',
      deleteCaseModelRequiredDocument: 'caseForms/deleteCaseModelRequiredDocument',
      deleteCaseFormWorkbenchTemplate: 'caseForms/deleteCaseFormWorkbenchTemplate'
    }),
    async changeCaseModelName () {
      this.loading = true
      const res = await this.$dialog.prompt({
        title: this.$t('case_models|case_model_name'),
        text: this.$t('actions|enter_new_name'),
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          type: 'text'
        }
      })
      if (!res) return
      const payload = {
        name: res
      }
      try {
        await this.updateCaseForm({ _id: this.currentCaseForm._id, payload })
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    },
    async changeCaseModelDescription () {
      this.loading = true
      const res = await this.$dialog.prompt({
        title: this.$t('case_model|description'),
        text: this.$t('actions|enter_new_description'),
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          type: 'text'
        }
      })
      if (!res) return
      const payload = {
        description: res
      }
      try {
        await this.updateCaseForm({ _id: this.currentCaseForm._id, payload })
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    },
    async addWorkbench () {
      const res = await this.$dialog.prompt({
        title: this.$t('mixed|workbench_name'),
        text: this.$t('actions|enter_workbench_name'),
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          type: 'text'
        }
      })
      if (!res) return
      try {
        const payload = {
          name: res
        }
        await this.addCaseFormWorkbench({ _id: this.currentCaseForm._id, payload })
      } catch (e) {
        console.error(e, 'error')
      }
    },
    async addWorkbenchTemplate () {
      EventBus.$emit('dialog-add-template-to-workbench', {
        callback: async (templateRef) => {
          const res = await this.$dialog.prompt({
            title: this.$t('templates|template_name'),
            text: this.$t('actions|enter_template_name'),
            textField: {
              // Any addtional props/attrs that will be binded to v-text-field component
              type: 'text'
            }
          })
          if (!res) return
          try {
            const payload = {
              templateRef,
              name: res
            }
            await this.addCaseFormWorkbenchTemplate({ _id: this.currentCaseForm._id, workbenchId: this.currentWorkbench._id, payload })
          } catch (e) {
            console.error(e, 'error')
          }
        }
      })
    },
    selectTemplate (visiblity = true) {
      this.templateIsSelected = visiblity
      if (this.templateIsSelected === false && this.$refs.workbench) {
        const workbench = this.$refs.workbench.find(w => w.workbench._id === this.selectedWorkbench)
        workbench.resetTemplate()
      }
    },
    onQuestionnaire (template) {
      EventBus.$emit('dialog-view-questionairre', {
        _id: this.currentCase._id,
        workbenchId: this.currentWorkbench._id,
        templateId: template._id,
        questionnaire: template.questionnaire
      })
    },
    async selectSuggestion (suggestion) {
      if (!suggestion) return
      try {
        const payload = {
          suggestion
        }
        await this.addCaseFormWorkbenchRequiredDocument({ _id: this.currentCaseForm._id, workbenchId: this.currentWorkbench._id, payload })
      } catch (e) {
        console.error(e, 'error')
      }
    },
    async deleteReqiredDocumentSuggestion (s) {
      this.loading = true
      const payload = {
        requiredDocumentsToDelete: s
      }
      try {
        await this.deleteCaseModelRequiredDocument({ _id: this.currentCaseForm._id, workbenchId: this.currentWorkbench._id, payload })
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    },
    async deleteWorkbenchTemplate (workbench, template) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure_you_want_to_delete_this_resource')
      })
      if (!res) return
      await this.deleteCaseFormWorkbenchTemplate({
        _id: this.currentCaseForm._id,
        workbenchId: workbench._id,
        templateId: template._id
      })
    },
    changeCategory (item) {
      EventBus.$emit('open-change-case-model-category-dialog', { item })
    },
    openEditDialog (event) {
      EventBus.$emit('dialog-edit-case-models-workbenches', {})
    },
    openGeneralQuestionnaire (event) {
      EventBus.$emit('dialog-general-questionnaire', {})
      this.generalQDialog = true
    }
  }
}
</script>
